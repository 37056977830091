.bx-wrapper {
	.bx-pager.bx-default-pager {
		a {
			background-color: #fff;
			border: 2px solid #525252;
			color: rgba(0, 0, 0, 0);
		}

		a:hover, a.active {
			background-color: #525252;
		}
	}

	.bx-next,
	.bx-prev {
		background: url("/content/themes/paramount/images/universal-nav-arrows.png");
	}

	.bx-next {
		background-position: -36px 0;

		&:hover {
			background-position: -36px 0;
		}
	}

	.bx-controls-direction {
		display: none;

		a {
			width: 36px;
			height: 37px;
			opacity: 0.8;
			z-index: 900;
		}
	}
}

.main-banner-wrap {
	.bx-wrapper {
		.bx-next {
			right: 5px;
		}

		.bx-prev {
			left: 5px;
		}
	}
}

.secondary-banner-wrap {
	max-width: 1000px;
	margin: 70px auto 0;
}

@media only screen and (max-width: 680px) {
	.secondary-banner-wrap .main-banner-wrap {
		margin-top: 20px;
	}
}

@media only screen and (min-width: 520px) {
	.bx-wrapper .bx-controls-direction {
		display: block;
	}
}

.secondary-banner-wrap .bx-wrapper .bx-controls-direction {
	display: none;
}

.slider--products {
	.slider-caption {
		font-size: 16px;
		font-weight: 600;
	}

	.bx-wrapper .bx-pager {
		bottom: 0px;
	}
}

.scroller-title {
	overflow: hidden;
}

.slider--featured .bx-viewport .slidewrap a:after {	
	color: #fff;
	background-color: $black;	
}

/*
@media (min-width: 1442px) {
	.main-banner-wrap.home-full {
		.bx-next {
			right: 12%;
		}

		.bx-prev {
			left: 12%;
		}
	}
}*/

/*
.slider-wrap {
	margin-top: 60px;
	margin-bottom: 60px;
	padding-bottom: 35px;
	background: none;
}*/

/*.main-banner-wrap.slider-wrap {
	.main-banner,
	.banner-feature {
		max-width: 1000px;
		margin: 0 auto;
	}
}*/
