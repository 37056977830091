$twitter: rgb(61, 199, 244); // #3dc7f4
$facebook: rgb(59, 89, 152); // #3b5998
$google: rgb(221, 75, 57); // #dd4b39
$linkedin: rgb(0, 101, 155); // #00659b
$pinterest: rgb(220, 62, 71); // #dc3e47
$youtube: rgb(228, 2, 2); // #e40202
$wordpress: rgb(36, 124, 164); // #247ca4
$rss: rgb(224, 142, 0); // #e08e00
$instagram: rgb(119, 62, 177); // #773eb1
$bangstyle: rgb(148, 151, 202); // #9497ca
$gray1: rgb(51, 51, 51); // #333333

.site-footer {
    background-color: $black;
    max-width: none;
    padding: 0;

    .google-translate {
        max-width: 1000px;        
    }
}

.klaviyo-form-TsEfKP {
    background-color: $blue-teal;
}

.footer-inner {
    max-width: 1000px;
    margin: 0 auto;
}

.site-footer__header {
  color: #fff;
  font-size: 20px;  
  font-style: normal;
  border-bottom: none;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-top: 20px;
}

.site-footer__item {
    font-size: 14px;
    line-height: 28px;
    padding-top: 0px;
}

.site-footer a {
    color: #fff;
}

@media only screen and (max-width: 680px) {
    .site-footer__panel {
        padding-left: 40px;
    }
}

// Social / contact us section
.i--bangstyle,
.i--email,
.i--facebook,
.i--feed,
.i--instagram,
.i--linkedin,
.i--pinterest,
.i--twitter,
.i--wordpress,
.i--youtube,
.i--tiktok {
  background: transparent
    url("/content/themes/paramount/images/social-icons.png") no-repeat;
  background-color: transparent;
}

.i--facebook {
  background-position: 0 0;
}

.i--instagram {
  background-position: -25px 0;
}

.i--pinterest {
  background-position: -50px 0;
}

.i--tiktok {
  background-position: -75px 0;
}

.i--email {
  background-position: -100px 0;
}

.i--youtube {
  background-position: -125px 0;
}

/*.i--linkedin {
  background-position: -542px 0;
}*/

.i--twitter-hover {
  background-color: $twitter;
}
.i--facebook-hover {
  background-color: $facebook;
}
.i--linkedin-hover {
  background-color: $linkedin;
}
.i--pinterest-hover {
  background-color: $pinterest;
}
.i--youtube-hover {
  background-color: $youtube;
}
.i--wordpress-hover {
  background-color: $wordpress;
}
.i--feed-hover {
  background-color: $rss;
}
.i--instagram-hover {
  background-color: $instagram;
}
.i--bangstyle-hover {
  background-color: $bangstyle;
}
.i--email-hover {
  background-color: $gray1;
}

.footer-social {
    color: #fff;
    border-bottom: 1px solid #fff;
    padding-top: 50px;

    .social {
        margin-top: 20px;
    }
}

@media only screen and (min-width: 820px) {
    .footer-social {
        width: 1000px;
        margin: 0 auto;        
    }
}

.footer-social__icons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
    column-gap: 10px;

    img {
        width: 93px;
    }
}

// Contact section
.footer-contact {
    margin-bottom: 10px;

    img {
        margin-right: 5px;
    }
}

.footer-contact > div {
  display: flex;  
  align-items: center;
  margin-bottom: 7px;
}

.footer-contact > div:first-of-type {
    justify-content: center;
}

@media only screen and (max-width: 680px) {
    .footer-contact > div {
        justify-content: center;
    }
}

// Copyright
.site-footer__copyright {
    margin-top: 0px;
    padding: 15px 0 12px;
    color: #fff;
    font-size: 10px;
}
