.bar {
	background-color: #702129;
}

.bar a {
	color: #fff;
}

.home-wrap .bar,
.catalogs__header {
	margin-top: 35px;
	margin-bottom: 20px;
	padding-top: 0;
	padding-bottom: 0;
	color: #000;
	font-size: 32px;
	font-weight: bold;
	text-align: center;
	text-transform: uppercase;
	background: none;
	overflow: visible;
}

.home-wrap .bar--instagram, .standard-banners--tertiary .bar {
	position: relative;

	span {
		display: inline-block;
		position: relative;
		z-index: 200;
		padding: 0 10px;
		background: #fff;
	}

	&:after {
		content: '';
		position: absolute;
		top: 50%;
		left: 0;
		z-index: 100;
		width: 100%;
		height: 5px;
		background: #b35c52;
		transform: translateY(-50%);
	}
}
