﻿.catalogs {
    max-width: 1000px;
    margin: 30px auto 60px;
    border-top: none;
    display: block;

    a {
        color: $color_highlight;
    }
}

.catalogs__header {
    border-bottom: none;
}

.catalog,
.catalog:last-child {
    border-bottom: none;    
}

.catalog__text::after {
    content: none;
}
