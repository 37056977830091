.home-articles {
	clear: both;
}

.home-articles__item {
	width: 50%;
}

.home-articles__item:nth-child(3n+1) {
	clear: none;
}

.home-article__title {
	margin-top: -50px;
	margin-right: 50px;
	margin-left: 50px;
	padding: 25px;
	color: #000;
	font-size: 42px;
	text-align: center;
	text-transform: none;
	background: #fff;
}

/*.home-article__title {
	font-style: normal;
	font-weight: 100;
	letter-spacing: 1px;
}*/

.home-article__teaser {
	display: none;
}

.home-articles__more {
	display: none;
}

@media only screen and (max-width: 680px) {
	.home-articles__item {
		float: none;
		width: 100%;
	}

	.home-article__title {
		margin-top: -25px;
		margin-right: 25px;
		margin-left: 25px;
		padding: 10px;
		font-size: 18px;
	}
}

.post__more {
	position: static;
}
