/* --- Redesign - home page About Us ---*/
.page--default {
	.about-us__wrapper {
		margin: 20px auto;
		background-color: $color_primary;
	}

	.about-us__inner {
		max-width: 1000px;
		margin: auto;
	}

	.about-us {
		/*width: 70%;*/
		float: right;
		vertical-align: middle;
		margin: 40px auto;
		padding: 30px 40px 40px;
		background: #fff;
		border-radius: 10px;
		min-height: 500px;
		box-shadow: $box-shadow;

		h2 {
			font-size: 40px;
			margin: 0.5em 0 0.5em;
		}

		a {
			margin-top: 10px;
		}
	}

	.about-us__title {
		font-size: 20px;
		font-weight: 600;
		margin-bottom: 0;
	}

	.about-us__text {
		font-size: 16px;
		line-height: 30px;
	}

	.about-us-menu {
		padding: 15px 0;
	}

	.about-us-menu__image {
		margin-left: -110px;
		width: 363px;
	}

	.about-us-items {
		top: 25%;
		left: 35%;
		position: absolute;
	}

	.about-us-item {
		margin: 25px 0px;
		display: flex;
		align-items: center;
		padding-top: 8px;

		a {
			display: inline-flex;

			&:hover {
				color: $black;
			}
		}

		p {
			display: inline-block;
			font-size: 22px;
			font-weight: 600;
			color: #000;
			margin: 0 0 0 10px;
			padding-top: 8px;
		}

		img {
			border-radius: 50%;
			background: #fff;
			padding: 8px;
			display: inline-block;
		}
	}

	@media only screen and (max-width: 999.98px) {
		.about-us {
			width: 100%;
		}
	}

	@media only screen and (max-width: 819.98px) {
		.about-us-items {
			left: 20%;
		}
	}

	@media only screen and (max-width: 680px) {
		.about-us-menu__image {
			margin-left: -35px;
			max-width: 65%;
		}

		.about-us-items {
			left: 50%;
		}

		.about-us-item {
			margin: 15px 0px;
			padding-top: 6px;

			p {
				font-size: 16px;
			}

			img {
				padding: 6px;
			}
		}

		.about-us {
			float: none;
			padding: 20px;
			width: 90%;
			min-height: inherit;
			margin-top: 10px;

			h2 {
				font-size: 26px;
				margin-top: 20px;
			}
		}

		.about-us__title {
			font-size: 14px;
		}

		.about-us__text {
			font-size: 12px;
			line-height: 20px;
		}
	}
}