.login {
	color: #000;
}

.login-form__row--actions {
	display: block;
}

.login-form__forgot {
	font-size: 11px;
}

.login-section__actions {
	margin-top: 20px;
}

.login-section__header {
	font-size: 1.8em;
}

.login-section {
	.button {
		padding: 8px 24px;		
		/*transition: background-color 0.3s ease-in-out;

		&:hover {
			background-color: darken(#3a6c93, 10%);
		}*/
	}
}

/*.login-section .button--text {
	background-color: transparent;
}*/