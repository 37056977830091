.site-nav {
    color: $black;
    background-color: #ffffff;
}

.site-nav__top {
  text-transform: capitalize;
  color: #000000;
}

.site-nav__sub.sub-nav {
    background: #fff;
    border-radius: 0px;
    border-top: 3px solid $color_highlight_menu;
    font-size: 14px;
    box-shadow: $box-shadow;
}

.site-nav__item:hover .site-nav__top {
    background-color: #fff;
    color: $color_highlight_menu;
}

@media only screen and (min-width: 820px) {
  .site-nav {
    margin-top: 0;
    display: flex;
    justify-content: center;
    background: none;
    max-width: none;
  }

  .site-nav__top {
    font-weight: 600;
    padding: 16px;
    font-size: 18px;
    border-radius: 0;
  }
}

.site-nav__item--promotions a {
    color: $color_highlight;

    &:hover {
        color: $color_highlight_menu;
    }
}

.site-nav__item--promotions .sub-nav__col a {
    color: $black;
}

.site-nav__item--holiday a {
    color: $color_holiday;

    &:hover {
        color: $color_highlight_menu;
    }
}

//------- global mobile changes ------//

.menu-wrap .search {
    display: none;
}

.nav-toggles,
.nav-toggle {
    background-color: #fff;
    justify-self: center;
}

.burger {
    width: 30px;
    height: 23px;
}

.burger__middle {
    background: #000;
    top: 10px;
}

.burger::before,
.burger::after {
    background: #000;
}

@media only screen and (max-width: 620px) {
    .menu-wrap {
        display: grid;
        grid-template-columns: 1fr 4fr;
        padding: 10px 0;

        .search {
            display: flex;
        }
    }
}