/*  --- all landing pages ---- */
.page--landing-default {
    .breadcrumb {
        display: none;
    }
}

/* ---- about us landing page ---- */
.landing-about-us {
    .landing & {
        h1 {
            margin-top: 10px;
            font-size: 25px;
        }

        p {
            font-size: 14px;
            line-height: 30px;
        }

        h2 {
            font-size: 22px;
            font-weight: 700;
        }
    }

    h1 {
        text-align: center;
        font-weight: 600;
    }

    @media only screen and (min-width: 480.02px) {
        .landing & {
            h1 {
                margin-top: 50px;
                font-size: 33px;
            }

            p.main-text {
                font-size: 20px;
            }
        }
    }

    @media only screen and (min-width: 820px) {
        .landing & {
            p {
                font-size: 17px;
                line-height: 32px;
            }

            p.main-text {
                font-size: 24px;
                line-height: 40px;
            }

            h2 {
                font-size: 28px;
            }
        }
    }

    .main-text {
        text-align: center;
        margin: auto;
        max-width: 800px;
    }

    .responsive-video {
        margin-bottom: 50px;
    }

    .about-us-border {
        border: 3px solid #000;
        height: 95%;
        width: 94%;
        top: 5%;
        left: 3%;
        position: absolute;
        z-index: 10;
    }

    .about-us-border-video {
        border: 3px solid #000;
        height: 100%;
        width: 100%;
        position: absolute;
    }

    .about-us-section {
        margin-top: 60px;
    }

    .about-us-section:last-of-type {
        margin-bottom: 30px;
    }

    .about-us-section--small {
        margin: 60px 35px 0 35px;

        .g-6 {
            padding: 0px;
        }

        .small-row__right {
            background: $color-primary;

            p {
                font-size: 13px;
                line-height: 24px;
            }
        }
    }

    .inner-box__text {
        padding: 0px 20px 20px;

        p {
            position: relative;
            z-index: 20;
        }
    }

    @media only screen and (min-width:680.02px) {
        .about-us-section--small {
            margin-left: 65px;
            margin-right: 65px;
            display: flex;

            .g-6 {
                margin: 0 5px;
            }

            .small-row__inner {
                padding: 0px 20px;
                top: 8%;
            }

            .small-row__right {                
                p {
                    font-size: 12px;
                    line-height: 16px;
                }
            }
        }

        .about-us-border {
            height: 90%;
        }

        .inner-box__text {
            padding: 40px 20px 20px;
        }

        .small-row__inner {
            padding: 0 20px;
            position: absolute;
            top: 10%;
        }
    }

    @media only screen and (min-width: 680.02px) and (max-width: 900px) {
        .landing & {
            p {
                font-size: 14px;
                line-height: 22px;
            }

            h2 {
                font-size: 20px;
            }
        }
    }

    @media only screen and (min-width: 820px) {
        .about-us-section--small {
            .small-row__inner {
                padding: 0px 25px;
                top: 10%;
            }

            .small-row__right {
                p {
                    font-size: 14px;
                    line-height: 22px;
                }
            }
        }

        .video-wrapper {
            padding: 25px;
        }

        .inner-box__text {
            padding-top: 50px;
        }

        .right-adj {
            padding-right: 100px;
            padding-left: 30px;
        }

        .left-adj {
            padding-left: 60px;
        }
    }

    @media only screen and (min-width: 1000px) {
        .about-us-section--small {
            .small-row__inner {
                padding: 0px 35px;
                top: 15%;
            }

            .small-row__right {
                p {
                    font-size: 17px;
                    line-height: 28px;
                }
            }
        }

        .inner-box__text {
            padding-top: 80px;
        }

        .inner-box__text.video-wrapper {
            padding-top: 50px;
        }
    }

    @media only screen and (max-width: 680px) {
        .about-us-section {
            display: grid;

            p {
                font-size: 14px;
            }

            img {
                margin: 0 auto;
            }
        }

        .about-us-section__inner {
            margin-top: 40px;
        }

        .about-us-section.image-right .g-5 {
            grid-column: 1/2;
            grid-row: 1/2;
        }

        .about-us-section .g-5 {
            padding: 0px;
        }

        .about-us-section--small {
            .small-row__right {
                display: grid;
                align-items: center;
                padding: 25px;
            }
        }
    }
}