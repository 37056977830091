.popover__content .txt-center a {
	color: #702129;
}

.popover__coupon-text {
	position: absolute;
	top: 50%;
	right: 0;
	left: 0;
	z-index: 300;
	transform: translateY(-50%);
	margin: 0 auto;
	padding: 0 15px;
	font-size: 16px;
	font-weight: bold;
	text-align: center;
}

.popover__coupon-text::after {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: rgba(255, 255, 255, 1);
	transition: background 0.8s;
}

.popover__coupon-text--visible::after {
	background: rgba(255, 255, 255, 0);
	pointer-events: none;
}

.popover--dark .popover__background {
	background: rgba(0, 0, 0, 1);
	transition: background 0.6s;
}

.popover__close {
	z-index: 500;
}

.popover iframe {
	z-index: 400;
}
