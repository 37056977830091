//--------------------- Begin new mobile header ----------------//
$content-width: 1200px;

.top-header {
	background-color: #000;
}

.top-header__inner {
	display: grid;
}

.header-top__countdown {
	grid-row: 1;
	width: 100%;
	color: #fff;
	width: 100%;
	text-align: right;
	max-width: $content-width;
	padding-right: 8px;
	margin: 0 auto;
}

.header-top__note {
	display: none;
}

.header-top__note--mobile {
	grid-row: 1;
	padding: 5px;
	text-align: center;
}

.header-top__assistance {
	grid-column: 1;
	padding: 0;
	max-width: 235px;
	line-height: 1.4;
	text-transform: none;
}

.header-top__assistance--mobile {
	display: none;
}

.header-top__assistance,
.header-top__assistance--mobile {
	color: #fff;
	background-color: #000;

	a {
		color: #fff;
		text-decoration: underline;
	}
}

.site-header .google-translate {
	max-width: $content-width;
	padding-left: 10px;
}

.utility-bar {
	grid-row: 2;
}

.utility-bar__inner {
	grid-template-columns: 1fr auto auto;
	align-items: center;
	max-width: $content-width;
	padding: 0 12px;

	.is--logged-out & {
		padding: 12px;
	}

	a {
		color: white;
	}
}

.login-links {
	grid-column: 2;
	text-transform: uppercase;
	color: #fff;
	font-size: 15px;
	letter-spacing: 0.5px;
	font-weight: 700;
}

.account-header {
	grid-column: 2;
}

.header-cart {
	grid-column: 3;
}

.login-links,
.header-cart {
	margin-right: 8px;
}

.header-search {
	grid-column: 4;

	.utility-bar & {
		width: auto;
		max-width: 250px;
		justify-self: baseline;
		margin-left: 5px;
	}
}

.search__options {
	background: #393738;

	select {
		color: #fff;

		option {
			background: #393738;
		}
	}
}

.account-header__icon {
	display: block;
	justify-self: right;
}

.account-header__toggle {
	display: block;
	justify-self: left;
}

.shop-location {
	padding-left: 8px;
	padding-bottom: 8px;
	grid-row: 3;
	margin: 0 auto;
	max-width: $content-width;
	width: 100%;
}

//------- Beauty connection NAV----------------
.header-top-sites-container {
	grid-row: 4;
	background-color: #f1f1f1;
}

.header-top-flex-items {
	max-width: $content-width;
	margin: 0 auto;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
	justify-content: start;
	font-size: 12px;

	.parent-site {
		background-color: #fff;
	}

	.beautyconnection-site {
		background: #ea50b2;

		a {
			color: #fff;
		}
	}
}

.header-top-site-item {
	padding: 8px 20px;
	cursor: pointer;
	height: 100%;
	text-align: center;
	letter-spacing: 0.5px;
	font-weight: 600;

	a {
		color: #000;
	}
}

.header-site-link__sub {
	font-style: italic;
	font-size: 12px;
	font-weight: normal;
	margin: 0px;
	margin-top: -5px;
	letter-spacing: 0;
}

.header-top-site__text {
	display: none;
}

.site-logo {
	padding: 0;
	margin: 0 auto;
}

.site-logo--mobile {
	display: none;
}

@media only screen and (min-width: 820px) {
	.header-top-flex-items {
		display: grid;
		grid-template-columns: 205px 135px 1fr;
		font-size: 16px;
	}

	.header-top-site__text {
		display: inline;
		font-size: 16px;
		font-style: italic;
		grid-row: 1;
		grid-column: 3;
		text-align: center;
		padding-right: 0px;
	}

	.site-header .google-translate {
		padding-top: 110px;

		//set to 140px when store pickup is turned on
		.is--logged-in & {
			padding-top: 110px;
		}
	}

	.title-wrap {
		padding-top: 0em;
	}

	.utility-bar .header-search {
		max-width: 300px;
	}
}

@media only screen and (min-width: 1000px) {
	.header-top-site__text {
		text-align: right;
		padding-right: 150px;
	}
}

@media (max-width: 768px) {
	.header-top-site-item {
		flex: 1 1 50%;
		text-align: center;
		margin: 0
	}
}

@media only screen and (max-width: 620px) {
	.top-header {
		background-color: unset;
	}

	.header-top__note--mobile {
		width: 100%;
		grid-column: 1 / span 2;
	}

	.locator-feature {
		grid-row: 2;
	}

	.header-top__assistance {
		display: none;
		grid-column: unset;
	}

	.header-top__assistance--mobile {
		display: block;
		width: 100%;
		text-align: center;
		padding: 10px;
		grid-column: 1 / span 2;
	}

	.shop-location {
		margin: 0 auto;
		font-size: 12px;
		grid-column: 1 / span 2;
		text-align: center;
		padding: 0 10px 10px;
		font-size: 12px;
		background-color: #000;
	}

	.shop-location__icon {
		display: none;
	}

	.header-top-sites-container {
		grid-row: 4;
		grid-column: 1 / span 2;
	}

	.header-top__countdown {
		grid-row: 5;
		grid-column: 1 / span 2;
		text-align: center;
		color: unset;
		padding-bottom: 5px;
	}

	.site-logo {
		display: none;
	}

	.site-logo--mobile {
		grid-row: 6;
		padding: 10px;
		margin: 0 auto;
		display: block;
		grid-column: 1 / span 2;

		.site-logo__img {
			margin: 0 auto;
			width: 80%;
		}
	}

	.utility-bar {
		grid-row: 7;
		grid-column: 1 / span 2;
		background-color: #fff;
		padding: 0px;
	}

	.utility-bar__inner {
		grid-template-columns: 80% 20%;
		justify-items: center;
		padding: 0;

		a {
			color: #000;
		}
	}

	.account-header__nav {
		a {
			color: #fff;
		}
	}

	.account-header {
		width: 100%;
		grid-column: 1;
		color: #000;
		background-color: #fff;
	}

	.header-cart {
		grid-column: 2;
		justify-self: end;
	}

	.account-header__account-greeting {
		display: none;
	}

	.account-header__header-i-guess {
		display: grid;
		grid-template-columns: auto auto 75%;
		align-items: center;
	}

	.account-header__icon {
		grid-column: 1;
		grid-row: 1;

		.icon-person .icon-circle {
			stroke: #000;
			stroke-width: 1;
		}

		.icon-person .icon-body,
		.icon-person .icon-head,
		.account-header__toggle {
			fill: #000;
		}
	}	

	.account-header__toggle {
		grid-column: 2;
		grid-row: 1;
		padding: 5px 10px 0 5px;
	}

	.account-header__title {
		grid-column: 3;
		text-align: left;
	}

	.header-cart__icon svg,
	.account-header__toggle svg {
		fill: #000;
	}

	.header-cart__value {
		color: #fff;
	}

	.search {
		display: none;
	}

	.title-wrap,
	.is--logged-in .title-wrap,
	.is--logged-in .title-wrap--store,
	.header-banner {
		padding: 0px;
	}

	.login-links {
		grid-column: 1;
		justify-self: start;
		padding-left: 20px;
		color: #000;
	}

	.header-search {
		padding-right: 10px;
		width: 100%;
		grid-column: 2;
	}

	.search,
	.search__text {
		max-width: unset;
	}

	.search__field {
		padding: 10px;
	}

	.search__submit {
		flex: 0 0 20px;
	}

	.button--search {
		width: 20px;
		height: 20px;
		background-size: 20px 20px;
	}

	.search__options {
		display: none;
	}
}

@media only screen and (max-width: 400px) {
	.shop-location {
		font-size: 11px;
	}
}
//---------------------- End new mobile header -----------------//