.button {
	color: #fff;
	background-color: $black;

	&:hover {
		background-color: $black;
	}
}

.RadGrid_Silk .rgEditForm .button {
	color: #fff;
}

.button--line {
	color: #7e7e7e;
	background: #fff;
}

.button--line:hover {
	color: #7e7e7e;
	background: #eee;
}

.button--text {
	color: #005ca0;
	background: none;
	border: none;
}

.button--text:hover {
	color: #005ca0;
	background: none;
	text-decoration: underline;
}

.button--quickview {
	background: $black;
}

.button--pending {
	&::after {
		background-color: $black;
	}
}