@import "./styles/fonts";

$font_primary: "Montserrat", sans-serif;

$teal: #0F8DA6;
$blue-teal: #BFCDD0;
$matte-pink: #BF5F5C;
$light-blue: #238BA3;
$red: #AA0C0C; 
$pale-yellow: #FAF9F5;
$black: #000;

$color_primary: $blue-teal;
$color_highlight: $light-blue;
$color_highlight_menu: $matte-pink;
$color_holiday: $red;
$color_social: $pale-yellow;

$box-shadow: 3px 3px 8px rgba(0,0,0,.16);

@import "./styles/buttons";

@import "./styles/nav";

@import "./styles/home-slider";

@import "./styles/home-headers";

@import "./styles/header";

@import "./styles/home-feature";

@import "./styles/home-catalogs";

@import "./styles/home-articles";

@import "./styles/footer";

@import "./styles/popovers";

@import "./styles/videos";

@import "./styles/login";

@import "./styles/shopping-cart";

@import "./styles/product-list";

@import "./styles/dashboard";

@import "./styles/brand-stories";

@import "./styles/about";

@import "./styles/blog";

@import "./styles/education";

@import "./styles/instashop";

@import "./styles/sample-toggle";

html, body {
    color: $black;
    font-family: $font_primary;
}

h1 {
    font-size: 2.3em;
}

img {
    max-width: 100%;
    height: auto;
}

.has--pencil .pencil,
.has--pencil .pencil-multi {
    max-width: none;
}

.main-content {
  max-width: none;
}

.main-content__inner {
  .pencil {
    max-width: none;
  }

  > div {
    max-width: 1000px;
    margin: 0 auto;
  }
}

.page--default,
.page--about-us-default {
  .main-content__inner > div {
    max-width: none;
  }
}

.pencil-banner {
  max-width: none;
}

@media only screen and (min-width: 820px) {
  .main-banner-wrap {
    .banner-feature {
      img {
        max-width: none;
      }
    }
  }
}

.list-link {
    color: $black;
}

.post_title {
    display: none;
}

.instagram-widget {
  max-width: none;
}

.instagram-header-container {
    text-align: center;
    padding: 15px 0 20px;
    background-color: $color_social;
    margin-top: 50px;

    .bar {
        margin-bottom: 0px;
    }

    p {
        margin-top: 0px;
        font-size: 15px;
    }

    a {
        color: $black;
    }
}

.instagram-link-container {
    padding: 60px 0;
    margin: auto;
    text-align: center;
    background-color: $color_social;
}

// Remove last br on page
.home-wrap > br {
  display: none;
}

/* ---------------------------
    #tabs
--------------------------- */

.tabs-nav .tabs-nav--active {
    background: $color-highlight;
}