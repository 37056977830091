.post__title a {
  font-size: 20px;
  color: $black;
}

.post__teaser, .post__body {
  font-size: 16px;
}

.post__body h3 {
  font-size: 20px;
}