﻿.pbt-container {
    margin-bottom: 50px;

    .sub-text {
        font-size: 12px;
        line-height: 15px;
    }
}

.pbt-containerMg {
  margin-bottom: 65px;
}
// PBT Main Banner

.pbt-mainbanner {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.brand-story-container {
  position: relative;
}

// // BTN
.pbt-btn {
  text-align: center;
}

.pbt-top-btn {
  text-align: left;
}

.pbtBtn-inner {
  color: #fff;
  background-color: #be7c5a;
  border-radius: 8px;
  font-size: 1.2em;
}

// About the Brand
.pbt-titleTextCon {
  .pbt-titleCon {
    padding: 20px 0px;
    .pbt-titleInner {
      font-weight: 700;
    }
  }
  .pbt-text {
    .pbt-textInner {
      line-height: 30px;
    }
  }
}

// Header & Big Video / Image

.pbt-videoImg-container {
  .videoImag-container {
    text-align: center;
    .videoImag-hc {
      font-weight: 700;
      margin-bottom: 40px;
    }
  }
  .pbt-vidImg-block {
    background-color: #ccc;
  }
}

// Image / Text

.pbt-imagetext-container {
    margin-bottom: 100px;

    .pbt-sbheader {
        font-weight: 700;
    }

    .pbt-sbtext {
        line-height: 30px;
    }
        
    .pbt-imgouter-left {
        position: relative;
        top: -35px;
        left: -35px;
        z-index: 110;
    }

    .pbt-imgouter-right {
        position: relative;
        top: -35px;
        right: -35px;
        z-index: 110;
    }
}

// Center Text
.pbt-text-container {
  margin-bottom: 65px;

  .pbt-videoImag-container {
    text-align: center;

    .pbt-videoImag-hc {
      font-weight: 700;
      margin-bottom: 40px;
    }
  }
  .pbt-textblock-container {
    .pbt-textblock {
      text-align: center;
      line-height: 30px;
    }
  }
}

// Two Images
.pbt-twoimages-container {
  margin-bottom: 65px;

  .pbt-twoimages-headerCon {
    text-align: center;

    .pbt-twoimages-header {
      font-weight: 700;
      margin-bottom: 40px;
    }
  }
}

// Three Images
.pbt-threeimages-container {

    .pbt-threeimages-headerCon {
        text-align: center;

        .pbt-threeimages-header {
            font-weight: 700;
            margin-bottom: 40px;
            margin-top: 50px;
        }
    }
}

// Single Image
.pbt-imgSBanCon {
  margin-bottom: 65px;
}

@media (min-width: 768px) {
  .pbt-logoCon.left {
    position: absolute;
    top: 150px;
    left: 100px;
    z-index: 200;
  }

  .pbt-logoCon.right {
    position: absolute;
    top: 150px;
    right: 100px;
    z-index: 200;
  }

  .pbtLogo {
    font-size: 4em !important;
    font-weight: 700;
    color: #4c4c4c;
  }
  // BTN

  .pbtBtn-inner {
    padding: 15px 40px;
  }
  // About the Brand
  .pbt-titleTextCon {
    padding: 0px 0px 0px 40px;
  }

  // Image / Text

  .pbt-imagetext-container {
    margin-top: 100px;
    margin-bottom: 100px;

    .ht-container-right {
      padding-right: 100px;
    }
    .ht-container-left {
      padding-left: 100px;
    }
    .pbt-imgouter-left {
      position: relative;
      top: -35px;
      left: -35px;
      z-index: 110;
    }
    .pbt-imgouter-right {
      position: relative;
      top: -35px;
      right: -35px;
      z-index: 110;
    }
    .pbt-imgBx::after {
      content: "";
      position: absolute;
      top: 0;
      right: 15px;
      bottom: 0;
      left: 15px;
      z-index: 100;
      border: 3px solid #be7c5a;
    }
  }
}

@media (max-width: 767.98px) {
  .pbt-logoCon.left {
    position: absolute;
    top: 65px;
    left: 35px;
    z-index: 200;
  }

  .pbt-logoCon.right {
    position: absolute;
    top: 65px;
    right: 35px;
    z-index: 200;
  }

  .pbtLogo {
    font-size: 2em !important;
    font-weight: 700;
    color: #4c4c4c;
  }
  // BTN

  .pbtBtn-inner {
    padding: 5px 15px;
  }
  // Image / Text

  .pbt-imagetext-container {
    margin-bottom: 100px;

    .ht-container-right {
      padding-right: 0px;
    }
    .ht-container-left {
      padding-left: 0px;
    }
    .pbt-imgouter-left {
      position: relative;
      top: -15px;
      left: -5px;
      z-index: 110;
    }
    .pbt-imgouter-right {
      position: relative;
      top: -15px;
      right: 5px;
      z-index: 110;
    }
    .pbt-imgBx::after {
      content: "";
      position: absolute;
      top: 0;
      right: 3px;
      bottom: 0;
      left: 15px;
      z-index: 100;
      border: 3px solid #be7c5a;
    }
  }
}

@media (max-width: 575.98px) {  
  .brand-story-summary__image {
    margin-bottom: 50px;
  }

  .pbt-grid {
    display: grid;
  }

  .pbt-inner-grid {
    grid-row: 1/1;
  }

  .pbtmobile-margin {
    margin-top: 50px;
  }
}

.brand-story-article {
    text-align: center;
}

.landing .brand-story-article__image {
    display: inline-block;
}


@media only screen and (min-width: 680.02px) {
    .brand-story-articles {
        display: flex;
        margin-bottom: 60px;
    }
}