.instashop-banner {
	font-size: 16px;
	font-weight: 200;
	text-align: center;
}

.instashop-banner__title {
	color: #000;
	font-family: corundum-text,serif;
	font-weight: bold;
}

.instashop-grid {
	padding: 0 15px;
}

.instashop-grid__item {
	margin-bottom: 15px;
}

@media only screen and (min-width: 420px) {
	.instashop-grid {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 10px;
		padding: 0;
	}

	.instashop-grid__item {
		margin-bottom: 0;
	}
}

@media only screen and (min-width: 720px) {
	.instashop-grid {
		gap: 20px;
	}
}

.instashop-link {
	.instashop-link__image {
		display: block;
		max-width: 100%;
		height: auto;
		margin: 0 auto;
	}
}
