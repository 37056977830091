.cart__row[data-apiqty="0"] .cart__cell {
	background-color: rgba(128, 38, 40, 0.5);
	color: #fff;

	.link-list, 
	.price__label--salon {
		color: #fff;
	}
}

.sample-featured {
	background-color: #E5EBEC;
}
