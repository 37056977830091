/*@font-face {
    font-family: "Libre Bodoni";
    font-display: swap;
    font-style: normal;
    font-weight: 400;
    src: url("/content/themes/paramount/fonts/libre-bodoni-v5-latin-regular.woff2") format("woff2"), url("/content/themes/paramount/fonts/libre-bodoni-v5-latin-regular.woff") format("woff");
}
*/

@font-face {
    font-display: fallback;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    src: url("/content/themes/paramount/fonts/montserrat-v25-latin-regular.woff2") format("woff2"), url("/content/themes/paramount/fonts/montserrat-v25-latin-regular.woff") format("woff");
}

@font-face {
    font-display: fallback;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    src: url("/content/themes/paramount/fonts/montserrat-v25-latin-700.woff2") format("woff2"), url("/content/themes/paramount/fonts/montserrat-v25-latin-700.woff") format("woff");
}

@font-face {
    font-display: fallback;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 100;
    src: url("/content/themes/paramount/fonts/montserrat-v25-latin-100.woff2") format("woff2"), url("/content/themes/paramount/fonts/montserrat-v25-latin-100.woff") format("woff");
}

@font-face {
    font-display: fallback;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    src: url("/content/themes/paramount/fonts/montserrat-v25-latin-600.woff2") format("woff2"), url("/content/themes/paramount/fonts/montserrat-v25-latin-600.woff") format("woff");
}

