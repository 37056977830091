.price__label--sale,
.price__label--savings {
	color: $color_highlight;
}

.price__label--highlight {
	color: #fff;
	background-color: #1e7a8f;
}

.prod-option:hover, 
.related-prod:hover {
	border-color: $color_highlight;
}

.filters__header,
.product-bar {
	background-color: $color_primary;
}

.pagination__link--active,
.pagination__link:hover {
	color: #fff;
	background-color: $black;
}

.deal-sku__link {
	color: $color_highlight;
}